// 优惠卷专区
import * as API from '@/api/index'
export default {
    /**  优惠劵列表*/
    GetCoupon: params => {
        return API.POST('api/Coupon/getCoupon', params)
    },
    /**  优惠劵新增*/
    AddCoupon: params => {
        return API.POST('api/Coupon/addCoupon', params)
    },
    /**  优惠劵编辑*/
    UpdateCoupon: params => {
        return API.POST('api/Coupon/updateCoupon', params)
    },
    /**  商品列表*/
    GoodAll: params => {
        return API.POST('api/Commodity/all', params)
    },
    /**  商品分类*/
    GoodType: () => {
        return API.POST('api/Commodity/GoodType')
    },
    /**  线上分类 - 排除没有子类的分类*/
    AppletGoodsCategoryValid: params => {
        return API.POST('api/appletGoodsCategory/valid', params)
    },
    /**  会员等级*/
    CustomerLevel: params => {
        return API.POST('api/customerLevel/all', params)
    },
    /**  获取商品信息*/
    GoodInfo: params => {
        return API.POST('api/Goods/allGoods', params)
    },
}