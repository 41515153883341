<template>
  <div class="MoneyCoupon content_body">
    <!-- 搜索框 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="优惠劵名称">
              <el-input
                v-model="couponName"
                @clear="handleSearch"
                clearable
                placeholder="输入劵名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                @change="handleSearch"
                v-model="couponActive"
                placeholder="请选择"
                clearable
              >
                <el-option label="未开始" :value="1"></el-option>
                <el-option label="进行中" :value="2"></el-option>
                <el-option label="已结束" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="handleCoupons(null, 'new')"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div>
      <el-table
        size="small"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
      >
        <el-table-column prop="Title" label="劵名称"></el-table-column>
        <el-table-column prop="couponPrice" label="面值"></el-table-column>
        <el-table-column prop="TotalAmoount" label="使用门槛"></el-table-column>
        <el-table-column
          label="使用有效期"
          width="300px"
          :formatter="fatValidity"
        ></el-table-column>
        <el-table-column
          label="剩余|总量"
          :formatter="fatGross"
        ></el-table-column>
        <el-table-column
          label="已使用|已领取"
          :formatter="fatReceived"
        ></el-table-column>
        <el-table-column prop="states" label="状态"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleCoupons(scope.row, 'edit')"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="handleCoupons(scope.row, 'copy')"
              v-prevent-click
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pad_10 dis_flex flex_x_end" v-if="paginations.total > 0">
      <el-pagination
        background
        :current-page.sync="paginations.page"
        :layout="paginations.layout"
        :total="paginations.total"
        @current-change="seckillPageChange"
      ></el-pagination>
    </div>
    <!-- 新增优惠劵 -->
    <el-dialog
      :title="DialogTitle"
      :visible.sync="dialogVisible"
      width="950px"
      @closed="dialogClose"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="0">
          <el-scrollbar class="el_scrollbar_height">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              size="small"
            >
              <el-form-item label="劵名称" prop="couponName">
                <el-input
                  style="max-width: 510px"
                  v-model="ruleForm.couponName"
                  maxlength="30"
                  show-word-limit
                  placeholder="最多输入10个汉字"
                >
                </el-input>
              </el-form-item>
              <div class="dis_flex flex_y_center">
                <el-col :span="8">
                  <el-form-item label="优惠金额" prop="price">
                    <el-input v-model="ruleForm.price">
                      <template slot="append">元</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="总发放量" prop="couponNumber">
                    <el-input v-model="ruleForm.couponNumber">
                      <template slot="append">张</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </div>

              <el-form-item label="有效期" required>
                <el-radio-group v-model="ruleForm.validityDateItem">
                  <div style="line-height: 40px">
                    <el-radio :label="0">固定期限</el-radio>
                    <el-date-picker
                      v-model="ruleForm.timeLimit"
                      type="datetimerange"
                      align="right"
                      size="small"
                      :disabled="ruleForm.validityDateItem != 0"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :default-time="['08:00:00', '17:00:00']"
                    >
                    </el-date-picker>
                  </div>
                  <el-row>
                    <el-col :span="6" style="line-height: 45px">
                      <el-radio :label="1">领取当日开始</el-radio>
                    </el-col>

                    <el-col :span="9">
                      <el-input
                        :disabled="ruleForm.validityDateItem != 1"
                        v-model="ruleForm.currtentData"
                        placeholder="请输入有效天数"
                      ></el-input>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="6" style="line-height: 45px">
                      <el-radio :label="2">领取次日开始</el-radio>
                    </el-col>

                    <el-col :span="9">
                      <el-input
                        :disabled="ruleForm.validityDateItem != 2"
                        v-model="ruleForm.nextData"
                        placeholder="请输入有效天数"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-radio-group>
                <p
                  v-if="
                    TimeBool &&
                    ruleForm.validityDateItem == 0 &&
                    !ruleForm.timeLimit.length
                  "
                  style="color: #f56c6c; line-height: 14px; font-size: 12px"
                >
                  请输入固定期限
                </p>
                <p
                  v-if="
                    TimeBool &&
                    ruleForm.validityDateItem == 1 &&
                    !ruleForm.currtentData
                  "
                  style="color: #f56c6c; line-height: 14px; font-size: 12px"
                >
                  请输入有效天数
                </p>
                <p
                  v-if="
                    TimeBool &&
                    ruleForm.validityDateItem == 2 &&
                    !ruleForm.nextData
                  "
                  style="color: #f56c6c; line-height: 14px; font-size: 12px"
                >
                  请输入有效天数
                </p>
              </el-form-item>
              <el-form-item label="使用门槛">
                <el-col :span="5">
                  <el-radio-group v-model="ruleForm.priceThresholdBool">
                    <el-radio :label="false">无门槛</el-radio>
                    <el-radio :label="true">订单满</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="4" v-if="ruleForm.priceThresholdBool">
                  <el-input
                    placeholder="请输入"
                    v-model="ruleForm.priceThreshol"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="每人限领">
                <el-col :span="5">
                  <el-radio-group v-model="ruleForm.couponNumThresholdBool">
                    <el-radio :label="false">不限领</el-radio>
                    <el-radio :label="true">限领</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="4" v-if="ruleForm.couponNumThresholdBool">
                  <el-input
                    placeholder="请输入"
                    v-model="ruleForm.couponNumThreshold"
                  >
                    <template slot="append">张</template>
                  </el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="过期提醒">
                <el-col :span="5">
                  <el-radio-group v-model="ruleForm.remindBool">
                    <el-radio :label="false">不提醒</el-radio>
                    <el-radio :label="true">提醒</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="6" v-if="ruleForm.remindBool">
                  <el-col :span="10">
                    <span>优惠劵过期前</span>
                  </el-col>

                  <el-col :span="8">
                    <el-input
                      placeholder="请输入"
                      v-model="ruleForm.remindNumDay"
                    >
                    </el-input>
                  </el-col>
                  <span>天提醒</span>
                </el-col>
              </el-form-item>
              <el-form-item label="身份限制">
                <el-radio-group v-model="ruleForm.identityThresholdBool">
                  <el-radio :label="false">不限制</el-radio>
                  <el-radio :label="true">限制</el-radio>
                </el-radio-group>
                <div>
                  <el-checkbox-group
                    v-if="ruleForm.identityThresholdBool"
                    v-model="ruleForm.identityCheckList"
                  >
                    <el-checkbox
                      v-for="(item, index) in CustomerLevel"
                      :key="index"
                      :label="item.ID"
                      >{{ item.Name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="可用商品">
                <el-col :span="6">
                  <el-radio-group v-model="ruleForm.assignBool">
                    <el-radio :label="false">全部商品</el-radio>
                    <el-radio :label="true">指定商品</el-radio>
                  </el-radio-group>
                </el-col>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane v-if="ruleForm.assignBool" label="指定商品" name="1">
          <!-- 搜索 -->
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item>
              <el-input
                v-model="searValue"
                clearable
                placeholder="输入商品线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addSpecifiedShop"
                >添加指定商品</el-button
              >
            </el-form-item>
          </el-form>
          <el-scrollbar class="el_scrollbar_height selGoods">
            <!-- 表格 -->

            <el-table
              :data="searValue ? searchGoods : selTableData"
              size="small"
              style="max-height: 950px"
            >
              <el-table-column label="主图缩略" width="80px">
                <template slot-scope="scope">
                  <el-image
                    style="width: 35px; height: 35px"
                    :src="scope.row.Image[0].ImgURL"
                  ></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="OnlineName" label="线上别名" width="210">
              </el-table-column>
              <el-table-column prop="Price" label="售价（元）" width="80px">
              </el-table-column>
              <el-table-column
                prop="CategoryName"
                label="线上分类"
                width="120px"
              >
              </el-table-column>
              <el-table-column prop="Sequence" label="线上排序" width="80px">
              </el-table-column>
              <el-table-column
                prop="GoodsTypeName"
                label="线下类型"
                width="80px"
              >
              </el-table-column>
              <el-table-column
                prop="OfflineName"
                label="线下别名"
                width="160px"
              >
              </el-table-column>
              <el-table-column prop="date" label="操作" width="100">
                <template>
                  <el-button type="danger" size="small" @click="handleSearch"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="上架网店" name="2">
          <el-scrollbar class="el_scrollbar_height">
            <el-tree
              class="padtp_10"
              show-checkbox
              :expand-on-click-node="false"
              node-key="ID"
              :data="entityList"
              :default-expanded-keys="[1]"
              :props="defaultProps"
            >
              <span slot-scope="{ data }">
                <span class="font_14">{{ data.EntityName }}</span>
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsStore"
                  >门店</el-tag
                >
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsWarehouse"
                  >仓库</el-tag
                >
              </span>
            </el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirm">保存</el-button>
      </span>
    </el-dialog>
    <!-- 添加指定商品弹出层 -->
    <el-dialog
      :title="SpecifiedShopDialogTitle"
      :visible.sync="SpecifiedShopDialogVisible"
      width="80%"
    >
      <el-row>
        <el-col :span="22">
          <!-- 搜索框 -->
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            label-width="80px"
            @keyup.enter.native="GoodsSearch"
          >
            <el-form-item label="线上名称">
              <el-input
                v-model="OnlineName"
                @clear="GoodsSearch"
                clearable
                placeholder="输入商品线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="线下名称">
              <el-input
                v-model="OfflineName"
                @clear="GoodsSearch"
                clearable
                placeholder="输入商品线下名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select
                @change="GoodsSearch"
                v-model="GoodsType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in GoodType"
                  :key="index"
                  :label="item.Name"
                  :value="item.GoodsTypeID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="线上分类">
              <el-cascader
                clearable
                v-model="CategoryID"
                :options="GoodsCategoryValid"
                :props="{ expandTrigger: 'hover' }"
                @change="GoodsSearch"
              ></el-cascader>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2"
          ><el-button type="primary" size="small" @click="GoodsSearch"
            >搜索</el-button
          ></el-col
        >
      </el-row>
      <el-scrollbar class="el_scrollbar_height Goods">
        <!-- 表格 -->
        <div>
          <el-table
            size="small"
            ref="multipleTable"
            :data="optionalTableData"
            tooltip-effect="light"
            :row-key="rowKeyFun"
            @selection-change="GoodsHandleSelectionChange"
          >
            <el-table-column
              :reserve-selection="true"
              type="selection"
              width="55"
            >
            </el-table-column>
            <el-table-column prop="ID" label="主图缩略">
              <template slot-scope="scope">
                <el-image
                  style="width: 35px; height: 35px"
                  :src="scope.row.Image[0].ImgURL"
                  fit="fit"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="OnlineName"
              label="线上别名"
            ></el-table-column>
            <el-table-column prop="Price" label="售价（元）"></el-table-column>
            <el-table-column
              prop="CategoryName"
              label="线上分类"
            ></el-table-column>
            <el-table-column prop="Sequence" label="线上排序"></el-table-column>
            <el-table-column
              prop="GoodsTypeName"
              label="线下类型"
            ></el-table-column>
            <el-table-column
              prop="OfflineName"
              label="线下名称"
            ></el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end" v-if="paginations.total > 0">
        <el-pagination
          background
          :current-page.sync="GoodsPaginations.page"
          :layout="GoodsPaginations.layout"
          :total="GoodsPaginations.total"
          @current-change="GoodsPageChange"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="selGoods"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/Marketing/MarketingPlay/MoneyCoupon";
import dateTime from "@/components/js/date";
export default {
  name: "MoneyCoupon",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      couponName: "", //优惠劵名称
      couponActive: "", //优惠劵状态
      tableData: [],
      selTableData: [], //已选择表格数据
      optionalTableData: [], //可选择的表格数据
      paginations: {
        total: "",
        layout: "prev, pager, next, jumper, ->, total, slot",
        page: 1,
      },
      GoodsPaginations: {
        total: "",
        layout: "prev, pager, next, jumper, ->, total, slot",
        page: 1,
      },
      dialogVisible: false, //新增优惠劵弹出层显隐
      DialogTitle: "", //新增优惠劵弹出层标题
      SpecifiedShopDialogVisible: false, //添加指定商品弹出层
      SpecifiedShopDialogTitle: "",
      activeName: "0",
      saveType: "",
      TimeBool: false,
      CustomerLevel: [], //会员等级列表
      ruleForm: {
        couponName: "", //卷名称
        price: "", //价格
        couponNumber: "", //发放量
        validityDateItem: 0, //有效期类型
        timeLimit: [], //类型1
        currtentData: "", //类型2
        nextData: "", //类型3
        priceThresholdBool: false, //使用门槛
        priceThreshol: "", //门槛金额
        couponNumThresholdBool: false, //限领
        couponNumThreshold: "", //限领张数
        remindBool: false, //过期提醒
        remindNumDay: "", //提醒天数
        identityThresholdBool: false, //身份限制
        identityCheckList: [], //身份列表
        assignBool: false, //可用商品
        listCommodityID: [], //商品ID
      },
      rules: {
        couponName: [
          { required: true, message: "请输入劵名称", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        price: [{ required: true, message: "请输入优惠金额", trigger: "blur" }],
        couponNumber: [
          { required: true, message: "请输入总发放量", trigger: "blur" },
        ],
      },
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      entityList: [
        {
          EntityName: "苏州哲联信息科技有限公司",
          IsStore: false,
          IsWarehouse: false,
          ID: 0,
          Child: [
            {
              EntityName: "珠江店",
              IsStore: true,
              IsWarehouse: false,
              ID: 1,
            },
            {
              EntityName: "万达店",
              IsStore: true,
              IsWarehouse: true,
              ID: 2,
            },
          ],
        },
      ],
      searValue: "",
      searchGoods: [],
      OnlineName: "", //线上
      OfflineName: "", //线下
      GoodsType: "", //类型
      CategoryID: "", //分类
      selTableData: [], //商品列表
      GoodType: [], //商品类型
      GoodsCategoryValid: [], //线上分类
      GoodsSel: [], //多选选中项
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    rowKeyFun(row) {
      return row.GoodsID;
    },
    // 获取优惠劵
    getCoupon() {
      const that = this;
      let params = { PageNum: that.paginations.page };
      let status;
      if (that.couponActive == 1) {
        status = "未开始";
      } else if (that.couponActive == 2) {
        status = "进行中";
      } else if (that.couponActive == 3) {
        status = "已结束";
      }
      if (that.couponName && that.status) {
        params = {
          Title: that.couponName,
          states: status,
          PageNum: that.paginations.page,
        };
      } else if (that.couponName) {
        (params.Title = that.couponName),
          (params.PageNum = that.paginations.page);
      } else if (status) {
        params.states = status;
        params.PageNum = that.paginations.page;
      }
      API.GetCoupon(params).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品类型
    getGoodType() {
      API.GoodType().then((res) => {
        if (res.StateCode == 200) {
          this.GoodType = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 线上分类 - 排除没有子类的分类
    getAppletGoodsCategoryValid() {
      API.AppletGoodsCategoryValid().then((res) => {
        if (res.StateCode == 200) {
          const arr = [];
          res.Data.forEach((res) => {
            const obj = {
              label: res.Name,
              value: res.CategoryID,
              children: [],
            };
            if (res.Child && res.Child.length) {
              res.Child.forEach((res) => {
                obj.children.push({
                  label: res.Name,
                  value: res.CategoryID,
                });
              });
            }

            arr.push(obj);
          });
          this.GoodsCategoryValid = arr;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取会员等级
    getCustomerLevel() {
      const that = this;
      API.CustomerLevel({}).then((res) => {
        if (res.StateCode == 200) {
          that.CustomerLevel = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品列表
    getGoodAll() {
      const that = this;
      const params = {
        PageNum: this.GoodsPaginations.page,
        OfflineName: this.OfflineName,
        GoodsType: this.GoodsType,
        OnlineName: this.OnlineName,
        CategoryID: this.CategoryID[1],
        Active: true,
      };
      API.GoodAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.optionalTableData = res.List;
          that.GoodsPaginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 有效期格式化
    fatValidity(row) {
      return row.StartDate + " " + "至" + " " + row.EndDate;
    },
    // 剩余，总量
    fatGross(row) {
      return row.surplus + " " + "|" + " " + row.TotalQuantity;
    },
    // 已使用，已领取
    fatReceived(row) {
      return row.used + " " + "|" + " " + row.Received;
    },
    // 优惠劵搜索
    handleSearch() {
      this.paginations.page = 1;
      this.getCoupon();
    },
    // 商品搜索
    GoodsSearch() {
      this.GoodsPaginations.page = 1;
      this.getGoodAll();
    },
    // 优惠劵列表页码改变
    seckillPageChange(e) {
      this.getCoupon();
    },
    // 商品列表页码改变
    GoodsPageChange(e) {
      this.getGoodAll();
    },
    // 添加指定商品弹出层
    addSpecifiedShop() {
      const that = this;
      that.SpecifiedShopDialogVisible = true;
      that.SpecifiedShopDialogTitle = "添加指定商品";
    },
    // 选择商品
    GoodsHandleSelectionChange(e) {
      this.GoodsSel = e;
    },
    // 保存选择指定商品
    selGoods() {
      const that = this;
      if (!that.GoodsSel.length) {
        that.$message.error({
          message: "请选择商品",
          duration: 2000,
        });
        return;
      }
      that.SpecifiedShopDialogVisible = false;
      that.selTableData.push(...that.GoodsSel);
    },
    // 打开新增编辑复制弹出层
    handleCoupons(row, type) {
      const that = this;
      that.dialogVisible = true;
      that.saveType = type;
      if (type == "new") {
        that.DialogTitle = "新增优惠劵";
      } else {
        that.DialogTitle = type == "edit" ? "编辑优惠劵" : "复制优惠劵";
        const params = {
          CouponID: row.CouponID, //优惠劵ID
          couponName: row.Title, //卷名称
          price: row.couponPrice, //价格
          couponNumber: row.TotalQuantity, //发放量
          validityDateItem: 0, //有效期类型
          timeLimit: [row.StartDate, row.EndDate], //类型1
          currtentData: "", //类型2
          nextData: "", //类型3
          priceThresholdBool: row.Threshold, //使用门槛
          priceThreshol: row.TotalAmoount, //门槛金额
          couponNumThresholdBool: row.LimiReceive, //限领
          couponNumThreshold: row.ReceiveQuantity, //限领张数
          remindBool: row.DateRemind, //过期提醒
          remindNumDay: row.remindDays, //提醒天数
          identityThresholdBool: row.IDenTits, //身份限制
          identityCheckList: row.limitLevel, //身份列表
          assignBool: row.IsGoods, //可用商品
          listCommodityID: [], //商品ID
        };
        if (row.limitLevel) {
          const str = row.limitLevel.slice(0, row.limitLevel.length - 1);
          params.identityCheckList = str.split(",").map(parseFloat);
        } else {
          params.identityCheckList = [];
        }
        if (row.CommodityIDs) {
          const str = row.CommodityIDs.slice(0, row.CommodityIDs.length - 1);
          params.listCommodityID = str.split(",").map(parseFloat);
          API.GoodInfo(params.listCommodityID).then((res) => {
            const arr = [];
            console.log(res);
            res.forEach((item) => {
              console.log(item);
              const obj = {
                Image: [{ ImgURL: item[0].ImgURL }],
                GoodsTypeName: item[0].TypeName,
                Price: item[0].Price,
                Price: item[0].Price,
                OnlineName: item[0].Name,
                GoodsID: item[0].GoodsID,
              };
              arr.push(obj);
            });
            console.log(arr);
            that.selTableData = arr;
          });
        }
        if (row.StartDate && row.EndDate) {
          params.validityDateItem = 0;
          params.timeLimit = [row.StartDate, row.EndDate];
        } else if (row.getDayStart) {
          params.validityDateItem = 1;
          that.$nextTick(() => {
            params.currtentData = row.getDayStart;
          });
        } else if (row.getTwoStart) {
          params.validityDateItem = 2;
          that.$nextTick(() => {
            params.nextData = row.getTwoStart;
          });
        }
        this.ruleForm = params;
      }
    },
    // 保存处理
    save() {
      const that = this;
      let start = "";
      let end = "";
      if (that.ruleForm.timeLimit.length) {
        start = dateTime.formatDate.format(
          new Date(that.ruleForm.timeLimit[0]),
          "YYYY-MM-DD hh:mm:ss"
        );
        end = dateTime.formatDate.format(
          new Date(that.ruleForm.timeLimit[1]),
          "YYYY-MM-DD hh:mm:ss"
        );
      }
      const params = {
        Title: that.ruleForm.couponName, //卷名称
        couponPrice: that.ruleForm.price, //优惠金额
        TotalQuantity: that.ruleForm.couponNumber, //总数量
        StartDate: start,
        EndDate: end,
        getDayStart: that.ruleForm.currtentData,
        getTwoStart: that.ruleForm.nextData,
        Threshold: that.ruleForm.priceThresholdBool, //门槛
        TotalAmoount: that.ruleForm.priceThreshol, //门槛金额
        LimiReceive: that.ruleForm.couponNumThresholdBool, //限领
        ReceiveQuantity: that.ruleForm.couponNumThreshold, //限领数量
        DateRemind: that.ruleForm.remindBool, //提醒
        remindDays: that.ruleForm.remindNumDay, //天数
        IDenTits: that.ruleForm.identityThresholdBool, //限制身份
        listLevel: that.ruleForm.identityCheckList, //身份列表
        IsGoods: that.ruleForm.assignBool, //指定商品
        listCommodityID: that.ruleForm.listCommodityID, //商品ID
      };
      if (that.saveType == "edit") {
        params.CouponID = that.ruleForm.CouponID;
      }
      const api = that.saveType == "edit" ? "UpdateCoupon" : "AddCoupon";
      API[api](params).then((res) => {
        if (res.StateCode == 200) {
          that.dialogVisible = false;
          that.handleSearch();
          that.$message.success({
            message:
              that.saveType == "copy"
                ? "复制成功"
                : that.saveType == "edit"
                ? "修改成功"
                : "添加成功",
            duration: 2000,
          });
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    confirm() {
      const that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          switch (that.ruleForm.validityDateItem) {
            case 0:
              if (!that.ruleForm.timeLimit.length) {
                that.TimeBool = true;
                return;
              }
              break;
            case 1:
              if (!that.ruleForm.currtentData) {
                that.TimeBool = true;
                return;
              }
              break;
            case 2:
              if (!that.ruleForm.nextData) {
                that.TimeBool = true;
                return;
              }
              break;
          }

          if (
            that.ruleForm.priceThresholdBool &&
            !that.ruleForm.priceThreshol
          ) {
            that.$message.error({
              message: "请输入使用门槛金额",
              duration: 2000,
            });
            return;
          }
          if (
            that.ruleForm.couponNumThresholdBool &&
            !that.ruleForm.couponNumThreshold
          ) {
            that.$message.error({
              message: "请输入每人限领数量",
              duration: 2000,
            });
            return;
          }
          if (
            that.ruleForm.identityThresholdBool &&
            !that.ruleForm.identityCheckList.length
          ) {
            that.$message.error({
              message: "请选择身份限制等级",
              duration: 2000,
            });
            return;
          }
          if (that.selTableData.length) {
            const arr = [];
            that.selTableData.forEach((item) => {
              arr.push(item.GoodsID);
            });
            that.ruleForm.listCommodityID = arr;
          }
          if (
            that.ruleForm.assignBool &&
            !that.ruleForm.listCommodityID.length
          ) {
            that.$message.error({
              message: "请选择指定商品",
              duration: 2000,
            });
            return;
          }

          // 验证通过
          that.save();
        } else {
          switch (that.ruleForm.validityDateItem) {
            case 0:
              if (!that.ruleForm.timeLimit.length) {
                that.TimeBool = true;
              }
              break;
            case 1:
              if (!that.ruleForm.currtentData) {
                that.TimeBool = true;
              }
              break;
            case 2:
              if (!that.ruleForm.nextData) {
                that.TimeBool = true;
              }
              break;
          }
        }
      });
    },
    // 关闭新增优惠劵弹出层
    dialogClose() {
      this.$refs.ruleForm.resetFields();
      this.TimeBool = false;
      this.ruleForm = {
        couponName: "", //卷名称
        price: "", //价格
        couponNumber: "", //发放量
        validityDateItem: 0, //有效期类型
        timeLimit: [], //类型1
        currtentData: "", //类型2
        nextData: "", //类型3
        priceThresholdBool: false, //使用门槛
        priceThreshol: "", //门槛金额
        couponNumThresholdBool: false, //限领
        couponNumThreshold: "", //限领张数
        remindBool: false, //过期提醒
        remindNumDay: "", //提醒天数
        identityThresholdBool: false, //身份限制
        identityCheckList: [], //身份列表
        assignBool: false, //可用商品
        listCommodityID: [], //商品ID
      };
      this.activeName = "0";
      this.GoodsPaginations.page = 1;
      this.selTableData = [];
      this.GoodsSel = [];
    },
  },
  /** 监听数据变化   */
  watch: {
    // 活动时间
    "ruleForm.validityDateItem": {
      handler() {
        this.ruleForm.timeLimit = [];
        this.ruleForm.currtentData = "";
        this.ruleForm.nextData = "";
      },
    },
    "ruleForm.priceThresholdBool": {
      handler(val) {
        if (val == false) {
          this.ruleForm.priceThreshol = "";
        }
      },
    },
    "ruleForm.couponNumThresholdBool": {
      handler(val) {
        if (val == false) {
          this.ruleForm.couponNumThreshold = "";
        }
      },
    },
    "ruleForm.remindBool": {
      handler(val) {
        if (val == false) {
          this.ruleForm.remindNumDay = "";
        }
      },
    },
    "ruleForm.identityThresholdBool": {
      handler(val) {
        if (val == false) {
          this.ruleForm.identityCheckList = [];
        }
      },
    },
    "ruleForm.assignBool": {
      handler(val) {
        if (val == false) {
          this.ruleForm.listCommodityID = [];
        }
      },
    },
    // 搜索指定商品
    searValue: {
      handler(val) {
        this.searchGoods = this.selTableData.filter((item) => {
          return item.OnlineName.substr(0, val.length) == val;
        });
      },
    },
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    // 获取优惠劵
    this.getCoupon();
    // 获取会员等级
    this.getCustomerLevel();
    this.getGoodAll();
    // 获取商品类型
    this.getGoodType();
    // 线上分类 - 排除没有子类的分类
    this.getAppletGoodsCategoryValid();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style scoped lang="scss">
.MoneyCoupon {
  .el_scrollbar_height {
    height: 60vh;
    max-height: 60vh;
  }
  .el_scrollbar_height.Goods {
    height: 55vh;
  }
  .el_scrollbar_height.selGoods {
    height: 54.5vh;
  }
}
</style>
